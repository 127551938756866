import cn from 'classnames';

import Bubble from 'assets/main-page/bubble9.png';
import Circle from 'assets/main-page/circle.png';

import Help from '../img/help.svg';
import Protection from '../img/protection.svg';
import Styling from '../img/styling.svg';
import css from '../possibilities.css';

export const OnlineVoting = () => {
    return (
        <div className={css.section}>
            <div className={css.title}>Запускайте онлайн голосования</div>
            <div className={css.blocksWrap}>
                <div className={css.smallBlocks}>
                    <div className={cn(css.block, css.grey, css.order1)}>
                        <Protection />
                        <div className={css.blackText}>Защита от накруток с ограничением доступа</div>
                    </div>
                    <div className={cn(css.block, css.white)}>
                        <Help />
                        <div className={css.blackText}>Поддержка анонимных и открытых голосований</div>
                    </div>
                    <div className={cn(css.block, css.blue)}>
                        <img alt='cash' className={cn(css.icon, css.right)} src={Circle} />
                        <div className={css.whiteText}>Мгновенные результаты с возможностью поделиться итогами</div>
                    </div>
                    <div className={cn(css.block, css.grey)}>
                        <Styling />
                        <div className={css.blackText}>Возможность брендирования в корпоративном стиле</div>
                    </div>
                </div>
                <div className={cn(css.bigBlock, css.background3)}>
                    <div className={css.bigBlockTitle}>
                        Интерактивные голосования — это идеальный способ собрать мнение аудитории, будь то выбор нового
                        дизайна продукта или участие в корпоративных инициативах.
                    </div>
                    <div className={css.bigBlockText}>
                        <div>
                            С помощью конструктора голосования Тестограф вы можете создавать голосования, встраивать их
                            на сайт или разместить на мероприятии в виде QR кода.
                        </div>
                        <div>
                            Настраивайте дизайн страницы голосования, ограничивайте от накруток и повторных заполнений,
                            устанавливайте сроки участия и делитесь результатами голосования с участниками по ссылке или
                            сразу после заполнения.
                        </div>
                    </div>
                    <a
                        className={css.link}
                        href='https://www.testograf.ru/ru/capabilities-votes'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Узнать больше
                    </a>
                </div>
            </div>
            <img alt='bubble' src={Bubble} className={css.bubbleLeftBottom} />
        </div>
    );
};
