import { useMobile } from '@webapp/common/hooks/use-mobile';

import ArrowWithGears from 'assets/main-page/arrow-with-gears.png';
import { Button } from 'components/ui/button';

import css from './api-integration.css';

export const ApiIntegration: FC = () => {
    const isMobile = useMobile();
    return (
        <div className={css.apiIntegration}>
            <div className={css.container}>
                <div className={css.textWrap}>
                    <div className={css.title}>Интеграция по API</div>
                    <div className={css.description}>
                        API позволяет решать такие масштабные задачи как автоматизация процессов при помощи интеграции
                        testograf с CRM системами и внутренними базами данных вашей компании.
                    </div>
                    {isMobile && <img alt='cash' className={css.image} src={ArrowWithGears} />}
                    <div className={css.text}>
                        Вы можете автоматизировать отправку опросников по различным событиям и триггерам. Дополнительно
                        доступно получение результатов через веб-хуки. При необходимости встраивания анкеты на сайт
                        компании используйте автоматически сгенерированный код для виджета, pop-up окна или iframe.
                    </div>
                </div>
                <div className={css.blockWithImage}>
                    {!isMobile && <img alt='cash' className={css.image} src={ArrowWithGears} />}
                    <Button href='https://www.testograf.ru/ru/docs-private-api' className={css.apiIntegrationBtn}>
                        Документация API
                    </Button>
                </div>
            </div>
        </div>
    );
};
