import { AnalyzeData } from 'components/mainpage/sections/possibilities/components/analyze-data';
import { CustomerSurveys } from 'components/mainpage/sections/possibilities/components/customer-surveys';
import { OnlineVoting } from 'components/mainpage/sections/possibilities/components/online-voting';
import { OrganizeTesting } from 'components/mainpage/sections/possibilities/components/organize-testing';

import css from './possibilities.css';

export const Possibilities = () => {
    return (
        <div className={css.possibilities}>
            <OrganizeTesting />
            <CustomerSurveys />
            <OnlineVoting />
            <AnalyzeData />
        </div>
    );
};
