import cn from 'classnames';

import Bubble from 'assets/main-page/bubble3.png';
import Diagram from 'assets/main-page/diagram2.png';

import Api from '../img/api.svg';
import Exclamation from '../img/exclamation.svg';
import Pulse from '../img/pulse.svg';
import css from '../possibilities.css';

export const CustomerSurveys = () => {
    return (
        <div className={css.section}>
            <div className={css.title}>Проводите опросы клиентов</div>
            <div className={css.blocksWrap}>
                <div className={cn(css.bigBlock, css.background2)}>
                    <div className={css.bigBlockTitle}>
                        Узнавайте о мнении ваших клиентов с помощью платформы для опросов Тестограф.
                    </div>
                    <div className={css.bigBlockText}>
                        <div>
                            Собирайте ценные данные о качестве обслуживания, предпочтениях пользователей и степени их
                            удовлетворённости. Создавайте многоуровневые опросы и настраивайте логику анкеты в
                            зависимости от ответов клиентов, чтобы получить более точные результаты.{' '}
                        </div>
                        <div>
                            Делайте обоснованные выводы на основании автоматической аналитики и улучшайте клиентский
                            опыт. А предоставить руководству структурированный и наглядный отчёт о результатах поможет
                            кастомизация и настраивание отчётов в конструкторе.
                        </div>
                    </div>
                    <a
                        className={css.link}
                        href='https://www.testograf.ru/ru/capabilities-marketing'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Узнать больше
                    </a>
                </div>
                <div className={css.smallBlocks}>
                    <div className={cn(css.block, css.grey)}>
                        <Pulse />
                        <div className={css.blackText}>
                            Автоматический расчёт индексов, динамика индексов, построение графиков и кросс-таблиц
                        </div>
                    </div>
                    <div className={cn(css.block, css.grey, css.desktopBlocks)}>
                        <Api />
                        <div className={css.blackText}>
                            Интеграция с CRM с помощью API и автоматизация email- и sms-рассылок
                        </div>
                    </div>
                    <div className={cn(css.block, css.white, css.desktopBlocks)}>
                        <Exclamation />
                        <div className={css.blackText}>
                            Возможности сбора данных на сайте через виджеты и всплывающие окна
                        </div>
                    </div>
                    <div className={cn(css.block, css.blue)}>
                        <img alt='cash' className={cn(css.icon, css.left)} src={Diagram} />
                        <div className={css.whiteText}>
                            Готовые шаблоны опросов для маркетинговых исследований и опросов потребителей
                        </div>
                    </div>
                    <div className={cn(css.mobileBlocks, css.order)}>
                        <div className={cn(css.block, css.grey, css.width)}>
                            <Api />
                            <div className={css.blackText}>
                                Интеграция с CRM с помощью API и автоматизация email- и sms-рассылок
                            </div>
                        </div>
                        <div className={cn(css.block, css.white, css.width)}>
                            <Exclamation />
                            <div className={css.blackText}>
                                Возможности сбора данных на сайте через виджеты и всплывающие окна
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <img alt='bubble' className={css.bubbleRight} src={Bubble} />
        </div>
    );
};
