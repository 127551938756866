import css from './opportunities.css';

export const Tests: FC<{ title?: string }> = ({ title }) => (
    <div className={css.hr}>
        <h1>{title}</h1>
        <p className='MsoNormal'>
            Создание онлайн тестов с помощью платформы Тестограф открывает перед пользователями широкие возможности для
            организации тестирования в образовании, бизнесе и других сферах. Тестограф — это удобный сервис для создания
            тестов, который позволяет легко и быстро создать тест любой сложности. Платформа разработана таким образом,
            чтобы удовлетворить потребности как преподавателей, так и HR-специалистов, предоставляя множество функций
            для гибкой настройки и анализа результатов.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Возможности конструктора Тестограф</strong>
            </b>
        </h2>
        <h3 className='MsoNormal'>
            <b>
                <strong>Разнообразие типов вопросов</strong>
            </b>
        </h3>
        <p className='MsoNormal'>На Тестографе можно создавать тестирования с различными типами вопросов:</p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                Выбор одного ответа — участник выбирает один правильный ответ из нескольких предложенных вариантов.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Выбор нескольких ответов — позволяет выбрать несколько правильных ответов из предложенных.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Текстовый ответ — участник вводит свой вариант ответа в текстовое поле.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Порядок — вопрос, в котором необходимо расставить предложенные варианты в правильной последовательности.
            </li>
        </ul>
        <p className='MsoNormal'>
            Эти типы вопросов дают возможность создавать тесты как для образовательных целей, так и для оценки знаний
            или навыков сотрудников. Платформа для создания тестов поддерживает разнообразие в настройке вопросов, что
            делает тестирование более гибким и интересным.
        </p>
        <p className='alignCenter MsoNormal'>
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/auth/register'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Попробовать демо
            </a>
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Настройка тестирования с проходным баллом и балльной системой</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            На платформе можно настроить тесты с проходным баллом, что особенно полезно для аттестации сотрудников, где
            важно оценить, насколько хорошо участник справился с заданием. Альтернативный вариант — это система баллов,
            где участник просто набирает очки за правильные ответы без необходимости преодолевать установленный порог.
            Обе системы оценки можно легко настроить в зависимости от задач тестирования.
        </p>
        <h3 className='MsoNormal'>
            <b>
                <strong>Таймеры и рандомизация вопросов</strong>
            </b>
        </h3>
        <p className='MsoNormal'>
            Для тех, кто хочет создать более динамичные тесты, Тестограф предлагает возможность установки таймера как на
            весь тест, так и на отдельные вопросы. Это полезно там, где важна скорость реакции или временные ограничения
            для более объективной оценки знаний.
        </p>
        <p className='MsoNormal'>
            Тестограф поддерживает рандомизацию вопросов — вы можете настроить, чтобы из общего набора, например 100
            вопросов, участнику показывалось только 20 случайных. Это помогает предотвратить списывание и делает
            результаты тестирования более правдивыми.
        </p>
        <h3 className='MsoNormal'>
            <b>
                <strong>Защита от копирования и безопасность</strong>
            </b>
        </h3>
        <p className='MsoNormal'>
            Тестограф предоставляет функции для защиты. Можно запретить копирование текста со страницы, чтобы участники
            не могли легко скопировать вопрос и найти ответ в интернете. Это особенно актуально для образовательных и
            сертификационных тестов, где важна честная оценка знаний.
        </p>
        <h3 className='MsoNormal'>
            <b>
                <strong>Индивидуальные тексты завершения</strong>
            </b>
        </h3>
        <p className='MsoNormal'>
            В зависимости от результатов тестирования, можно настроить различные тексты завершения, чтобы каждый
            участник получил персонализированный отклик на свои результаты. Это может быть как мотивационное сообщение
            для тех, кто не прошел тест, так и поздравление для тех, кто справился с заданиями.
        </p>
        <h3 className='MsoNormal'>
            <b>
                <strong>Анализ и выгрузка результатов</strong>
            </b>
        </h3>
        <p className='MsoNormal'>
            Одна из ключевых функций платформы — это возможность просмотра и анализа результатов. После завершения
            тестирования пользователи могут увидеть сводный отчет, который включает таблицы и диаграммы, показывающие
            общие результаты всех участников. Это помогает понять, как тестируемые справились с заданиями, и выявить
            слабые и сильные стороны каждого.
        </p>
        <p className='MsoNormal'>
            Если необходимо сохранить результаты тестирования, Тестограф предлагает функцию выгрузки данных в Excel. В
            отчете будет доступна информация по каждому тестируемому: количество набранных баллов, пройден тест или нет,
            а также полный разбор ответов на каждый вопрос. Это удобная функция для тех, кто проводит массовое
            тестирование или аттестацию сотрудников и хочет сохранить результаты для дальнейшего анализа.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Демо лицензия Тестографа</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Для новых пользователей Тестограф предлагает Демо лицензию, которая позволяет бесплатно создать одно
            тестирование и сохранить до 10 ответов на него. Это отличный способ протестировать возможности платформы и
            оценить все её функции без необходимости сразу приобретать платную подписку. Демо лицензия подходит как для
            учебных целей, так и для небольших тестов внутри компании.
        </p>
        <p className='alignCenter MsoNormal'>
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/auth/register'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Попробовать демо
            </a>
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Готовые шаблоны</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            На Тестографе также доступны готовые шаблоны, которые помогут вам начать работу быстрее. Эти шаблоны можно
            редактировать, добавлять свои вопросы и настраивать под свои нужды. Это особенно удобно для тех, кто только
            начинает работать с платформой или хочет сэкономить время на создании тестов с нуля.
        </p>
        <ul>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/content-email-marketing-test-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Контент и email-маркетинг
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/advanced-english-test-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Английский язык, уровень Advanced
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/intermediate-english-test-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Английский язык, уровень Intermediate
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/szv-td-test-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Заполнение СЗВ-ТД
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/vacation-test-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Отпуска и отпускные
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/middle-javascript-developer-test-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    JavaScript для Middle-разработчика
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/hall-test-survey-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Шаблон Теста Холла
                </a>
            </li>
            <li className='MsoNormal'>
                <a
                    href='https://www.testograf.ru/ru/blog/lsia-survey-template'
                    target='_blank'
                    rel='noopener noreferrer nofollow'
                >
                    Индекс жизненной удовлетворенности (ИЖУ)
                </a>
            </li>
        </ul>
        <h2 className='MsoNormal'>
            <b>
                <strong>Преимущества использования Тестографа</strong>
            </b>
        </h2>
        <ul>
            <li style={{ marginTop: '0cm' }}>Быстрое создание с возможностью детальной настройки.</li>
            <li style={{ marginTop: '0cm' }}>Гибкость в настройке вопросов и вариантов ответов.</li>
            <li style={{ marginTop: '0cm' }}>Таймеры и возможность рандомизации вопросов.</li>
            <li style={{ marginTop: '0cm' }}>Защита от копирования текста на странице.</li>
            <li style={{ marginTop: '0cm' }}>Индивидуальные тексты завершения в зависимости от результатов.</li>
            <li style={{ marginTop: '0cm' }}>Подробная аналитика результатов с возможностью выгрузки в Excel.</li>
            <li style={{ marginTop: '0cm' }}>
                Демо лицензия - возможность бесплатно создать один онлайн тест и сохранения до 10 ответов на него.
            </li>
        </ul>
        <h2 className='MsoNormal'>
            <b>
                <strong>Итог</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Тестограф — это современный и удобный инструмент для создания и проведения тестов онлайн. Благодаря гибким
            настройкам, вы можете легко сделать тест, адаптировать его под свои нужды и анализировать результаты. Если
            вы ищете сервис для проведения тестирований, Тестограф станет отличным выбором как для образовательных, так
            и для бизнес-задач. Вы можете использовать конструктор тестов бесплатно для тестирования платформы и
            убедиться в её возможностях, начиная с демо лицензии.
        </p>
        <p className='alignCenter MsoNormal'>
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/auth/register'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Попробовать демо
            </a>
            &nbsp;
        </p>
        <h2>Видео-обучение по работе с Testograf:</h2>
        <div className='raw-html-embed'>
            <div style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '56.25%' }}>
                <iframe
                    src='https://vk.com/video_ext.php?oid=-69308878&amp;id=456239081'
                    style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}
                    allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
                    frameBorder='0'
                ></iframe>
            </div>
        </div>
    </div>
);
