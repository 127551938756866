export const agreementHTML = `<div class="docHTML">
<p>Настоящий документ &laquo;Пользовательское соглашение&raquo; представляет собой предложение ООО &laquo;ТЕСТОГРАФ&raquo; (далее &ndash; &laquo;Правообладатель&raquo;) заключить договор на изложенных ниже условиях.&nbsp;</p>
<p><br></p>
    <p style="text-align: center"><strong>1. ОБЩИЕ ПОЛОЖЕНИЯ</strong></p>
    <p><strong>1.1.</strong> В настоящем документе и вытекающих или связанных с ним отношениях применяются следующие термины и определения:</p>
    <p>а) <strong>Соглашение</strong> &ndash; текст настоящего документа со всеми приложениями, изменениями и дополнениями к нему, описывающий общие условия использования Платформы.</p>
    <p>б) <strong>Платформа</strong> &ndash; программный комплекс Правообладателя, включающий Сайт и иные интегрированные с ним программы для ЭВМ и/или базы данных, реализующие функциональные возможности Платформы для создания онлайн и оффлайн опросов, включая анкетирование, тестирование, голосование. и обработки результатов.</p>
    <p>в) <strong>Сайт&nbsp;</strong>&ndash; автоматизированная информационная система, доступная в сети Интернет по сетевому адресу <a href="https://www.testograf.ru" target="_blank">https://www.testograf.ru</a>, включая субдомены.</p>
    <p>г) <strong>Контент</strong> &ndash; любые информационные материалы, включая текстовые, графические, аудио, видео и прочие материалы, в том числе, информация о Пользователях и респондентах, результатах опросов и тестов, сообщения и уведомления, которые Пользователи загружают в Платформу и к которым получают доступ с использованием Платформы.</p>
    <p>д) <strong>Пользователь</strong> &ndash; лицо, заключившее настоящее Соглашение с Правообладателем в своих интересах и/или в интересах третьего лица в соответствии с требованиями действующего законодательства и настоящего Соглашения. В случае, если Пользователь зарегистрирован и/или использует Платформу от лица и/или в интересах организации (включая индивидуальных предпринимателей), действия Пользователя считаются действиями такой организации.</p>
    <p>е) <strong>Супер-админ</strong> – роль Пользователя, дающая доступ к Личным кабинетам других Пользователей Лицензиата.</p>
    <p>ж) <strong>Личный кабинет</strong> – персональный раздел Платформы, к которому Пользователь получает доступ после регистрации и/или авторизации в Платформе. Личный кабинет предназначен для хранения персональной информации Пользователя, просмотра и использования доступных функциональных возможностей Платформы, а также получения уведомлений. Доступ Пользователя к Личному кабинету осуществляется с использованием Аутентификационных данных.</p>
    <p>з) <strong>Аутентификационные данные</strong> – уникальная последовательность символов или иное средство, предназначенные для идентификации пользователя Платформы. В качестве Аутентификационных данных в Платформе используются: логин (адрес электронной почты) и пароль.</p>
    <p><strong>1.2.</strong> В настоящем Соглашении могут быть использованы иные термины и определения, не указанные в п.1.1. Соглашения. В этом случае толкование такого термина производится в соответствии с текстом Соглашения. В случае отсутствия однозначного толкования термина или определения в тексте Соглашения следует руководствоваться его толкованием, определенным: в первую очередь &ndash; указанными в нем обязательными документами, во вторую очередь &ndash; законодательством Российской Федерации, и в последующем &mdash; обычаями делового оборота и научной доктриной.</p>
    <p><strong>1.3.</strong> Использование вами Платформы любым способом и в любой форме в пределах его объявленных функциональных возможностей, включая:</p>
    <p> - просмотр или использование Контента;</p>
    <p> - регистрация и/или авторизация в Платформе;</>
    <p> - загрузку Контента в Платформу или размещение ссылок на него;</p>
    <p> - направление запроса через форму обратной связи;</p>
    <p> - иное использование Платформы,</p>
<p>создает договор на условиях настоящего Соглашения и указанных в нем обязательных документов в соответствии с положениями ст. 437 и 438 Гражданского кодекса Российской Федерации.</p>
    <p><strong>1.4.</strong> Воспользовавшись любой из указанных выше возможностей по использованию Платформы, вы подтверждаете, что:</p>
    <p>а) Ознакомились с условиями настоящего Соглашения и указанных в нем Обязательных документов в полном объеме до начала использования Платформы.</p>
    <p>б) Принимаете все условия настоящего Соглашения и указанных в нем Обязательных документов в полном объеме без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их соблюдать или прекратить использование Платформы. Если вы не согласны с условиями настоящего Соглашения и указанных в нем Обязательных документов или не имеете права на заключение договора на их основе, вам следует незамедлительно прекратить любое использование Платформы.</p>
    <p>в) Соглашение (в том числе любая из его частей) и/или указанные в нем Обязательные документы могут быть изменены Правообладателем без какого-либо специального уведомления. Новая редакция Соглашения и/или указанных в нем Обязательных документов вступает в силу с момента размещения на Сайте либо доведения до сведения Пользователя в иной удобной форме, если иное не предусмотрено новой редакцией Соглашения и/или указанных в нем Обязательных документов.</p>
    <p>г) Вы обязуетесь самостоятельно регулярно знакомиться с действующей редакцией Соглашения. Продолжение использования Платформы после изменения Соглашения признается Вашим согласием и принятием новой редакции Соглашения.&nbsp;</p>
    <p style="text-align: center"><strong>2. ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПЛАТФОРМЫ</strong></p>
    <p><strong>2.1.</strong> Обязательным условием заключения настоящего Соглашения является полное и безоговорочное принятие и соблюдение Пользователем в установленных ниже случаях требований и положений, определенных следующими документами (&laquo;Обязательные документы&raquo;):</p>
    <p>а) <strong>Политика конфиденциальности</strong>, размещенная и/или доступная в сети Интернет по адресу <a href="https://www.testograf.ru/ru/docs-privacy-policy" target="_blank">https://www.testograf.ru/ru/docs-privacy-policy</a> и содержащая правила предоставления и использования персональной информации Пользователей, включая персональные данные.</p>
    <p>б) <strong>Оферта о заключении лицензионного договора</strong>, которая размещена и//или доступна в сети Интернет по адресу <a href="https://www.testograf.ru/ru/public-offer" target="_blank">https://www.testograf.ru/ru/public-offer</a> и является обязательным документом в случае оформления Пользователем заказа на предоставление прав использования дополнительных функциональных возможностей Платформы.</p>
    <p>в) <strong>Документация</strong>, размещенная и/или доступная в сети Интернет по адресу <a href="https://www.testograf.ru/ru/functions" target="_blank">https://www.testograf.ru/ru/functions</a> , в которой описывается логика работы Программы, а также технические требования, условия и инструкции по использованию Программы.<ul>
    <p><strong>2.2.</strong> Использование Платформы в ограниченной части открытых для общего пользования разделов Сайта и направления запросов через формы обратной связи возможно без прохождения Пользователем предварительной регистрации и/или авторизации.</p>
    <p>В остальной части применение функциональных возможностей Платформы допускается после регистрации и/или авторизации Пользователя в Платформе в соответствии с установленными Правообладателем правилами.</p>
    <p><strong>2.3.</strong> Перечень функциональных возможностей Платформы, использование которых требует регистрации в Платформе или совершения Пользователем определенных дополнительных действий (например, заполнение профиля, подтверждение предоставленных сведений, заключение отдельного возмездного договора с Правообладателем) определяется по&nbsp;единоличному усмотрению Правообладателя и&nbsp;может время от&nbsp;времени изменяться.&nbsp;</p>
    <p><strong>2.4.</strong> При использовании Платформы Пользователь обязуется предоставлять достоверную и полную информацию о себе по вопросам, предлагаемым в форме регистрации и/ или авторизации в Платформе, а также при оформлении с использованием её программных средств заказа на оказание возмездных услуг, и поддерживать эту информацию в актуальном состоянии. Если Пользователь предоставляет неверную информацию или у Правообладателя есть основания полагать, что предоставленная Пользователем информация неполна или недостоверна, Правообладатель имеет право по своему усмотрению заблокировать либо удалить Личный кабинет Пользователя, а также отказать Пользователю в оформлении заказа на услуги или ином использовании Платформы полностью или в определенной части.&nbsp;</p>
    <p><strong>2.5.</strong> Правообладатель оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных, указанных при регистрации или оформлении заказа, и запросить в связи с этим подтверждающие документы, непредоставление которых, по усмотрению Правообладателя, может быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 2.4. Соглашения.&nbsp;</p>
    <p><strong>2.6.</strong> В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать Пользователя, Правообладатель вправе применить меры, указанные в п.2.4. Соглашения.</p>
    <p><strong>2.7.</strong> Технические, организационные и&nbsp;коммерческие условия использования Платформы могут доводиться до&nbsp;сведения Пользователей путем отдельного размещения в Платформе или путем уведомления Пользователей.</p>
    <p><strong>2.8.</strong> Правообладатель вправе устанавливать лимиты и вводить иные технические ограничения использования Платформы, которые время от времени будут доводиться до сведения Пользователей в форме и способом по выбору Правообладателя.</p>
    <p style="text-align: center"><strong>3. ГАРАНТИИ ПОЛЬЗОВАТЕЛЯ</strong></p>
<p>Принимая условия настоящего Соглашения, вы подтверждаете и гарантируете, что:</p>
    <p><strong>3.1.</strong> Вы обладаете всеми необходимыми правами и полномочиями для заключения настоящего Соглашения и его исполнения;</p>
    <p><strong>3.2.</strong> Использование Платформы будет осуществляться вами исключительно в рамках функционального назначения, с соблюдением положений настоящего Соглашения, Обязательных документов, а также требований применимого права и общепринятой практики;</p>
    <p><strong>3.3.</strong> Вы не будете совершать каких-либо действий, которые вступают в противоречие с функциональным назначением или препятствуют работе Платформы или работе соответствующего оборудования и сетей;</p>
    <p><strong>3.4.</strong> Использование вами Платформы для конкретных целей не нарушает имущественных и/или личных неимущественных прав третьих лиц, а равно запретов и ограничений, установленных применимым правом, а также права государства вашего места нахождения, включая без ограничения авторские и смежные права, права на товарные знаки, знаки обслуживания и наименования мест происхождения товаров, права на промышленные образцы, права на использование изображений людей;&nbsp;&nbsp;предоставляемый вами Контент и прочие данные не содержат сведений и/или образов, оскорбляющих честь, достоинство и деловую репутацию третьих лиц, а также информацию пропагандирующую насилие, порнографию, наркотики, расовую или национальную вражду; и вами получены все необходимые разрешения от уполномоченных лиц в связи с размещением Контента в Платформе.</>
    <p><strong>3.5.</strong> В случае предоставления персональных данных третьего лица, включая предоставление содержащих их документов, вами получено согласие субъекта персональных данных на их предоставление Правообладателю и их последующую обработку в соответствии с Политикой конфиденциальности.</p>
    <p><strong>3.6.</strong> Пользователи, получившие с использованием Платформы доступ к персональным данным других Пользователей, гарантируют их обработку в соответствии с действующим законодательством и Политикой конфиденциальности.</p>
    <p style="text-align: center"><strong>4. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</strong></p>
    <p><strong>4.1.</strong> Все объекты, доступные в Платформе, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки и другие объекты, используемые в Платформе, являются объектами исключительных прав Правообладателя.</p>
    <p><strong>4.2.</strong> Правообладатель предоставляет Пользователю право использования Платформы в пределах ее общих функциональных возможностей.</p>
    <p><strong>4.3.</strong> Использование Платформы иными способами, в том числе путем копирования (воспроизведения) размещенного в Платформе Контента, а также входящих в состав Платформе элементов дизайна, программ для ЭВМ и баз данных, их декомпиляция, модификация, и последующее распространение, публичный показ, создание производных продуктов или сервисов, строго запрещены, если иное не предусмотрено настоящим Соглашением, Обязательными документами или не следует из прав Пользователя на Контент.</p>
    <p style="text-align: center"><strong>5. ОГРАНИЧЕНИЯ</strong></p>
    <p>Соглашаясь с условиями настоящего Соглашения, вы понимаете и признаете, что:</p>
        <p><strong>5.1.</strong> Функциональные возможности Платформы предоставляются для использования на условиях &laquo;как есть&raquo; и &laquo;как доступно&raquo;, в связи с чем вам не представляются какие-либо гарантии в отношении Платформы, в том числе, но не ограничиваясь указанным: что Платформа будет соответствовать вашим требованиям; что Платформа будет функционировать непрерывно, быстро, надежно и без ошибок; что результаты, которые могут быть получены с использованием Платформы, будут точными и надежными; что качество какого-либо продукта, услуги, информации и Контента, полученных с использованием Платформы, будет соответствовать вашим ожиданиям; что все ошибки в Контенте и/или программном обеспечении Платформы будут исправлены.</p>
        <p><strong>5.2.</strong> Любая информация и/или материалы, любые иные инструкции и руководства, доступ к которым вы получаете с использованием Платформы, вы используете на свой собственный риск и самостоятельно несете ответственность за возможные последствия использования указанных информации и/или материалов, в том числе за убытки, которые это может причинить вам или третьим лицам, или любой другой вред.</p>
        <p><strong>5.3.</strong> Поскольку Платформа находится на стадии постоянного дополнения и обновления новых функциональных возможностей, форма и характер предоставляемых функциональных возможностей могут время от времени меняться без вашего предварительного уведомления. Правообладатель вправе по собственному усмотрению прекратить (временно или окончательно) предоставление доступа к Платформе (или каким-либо отдельным функциональным возможностям) всем Пользователям вообще или вам, в частности, без вашего предварительного уведомления.</p>
        <p><strong>5.4.</strong> При использовании Платформы Пользователю запрещено:</p>
        <p>а) загружать, посылать, передавать или любым другим способом размещать и/или распространять информацию, которая является незаконной, вредоносной, клеветнической, оскорбляет нравственность, демонстрирует (или является пропагандой) насилие и жестокость, нарушает права интеллектуальной собственности, пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному, социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является пропагандой) услуг сексуального характера (в том числе под видом иных услуг);</p>
        <p>б) нарушать права третьих лиц, в том числе несовершеннолетних лиц и/или причинять им вред в любой форме;</p>
        <p>в) выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав, в том числе за сотрудников Правообладателя, а также применять любые другие формы и способы незаконного представительства других лиц в сети Интернет, а также вводить Пользователей или Правообладателя в заблуждение относительно свойств и характеристик каких-либо субъектов или объектов;</p>
        <p>г) загружать, посылать, передавать или любым другим способом размещать и/или распространять Контент, при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям;</p>
        <p>д) несанкционированно собирать, хранить и использовать персональные данные других лиц;</p>
        <p>е) нарушать нормальную работу Платформы;</p>
        <p>ж) содействовать нарушению ограничений и запретов, налагаемых Соглашением;</p>
        <p><strong>5.5.</strong> Правообладатель никак не связан с Контентом, загруженным Пользователями в Платформу и/или распространяемым с его использованием, и не осуществляет проверку содержания, подлинности и безопасности такого Контента либо его компонентов, а равно их соответствия требованиям применимого права, и наличия у Пользователей необходимого объема прав на их получение, распространение и/или использование.</p>
        <p><strong>5.6.</strong> Пользователь самостоятельно несет ответственность за соответствие содержания загруженного и/или распространяемого им Контента требованиям действующего законодательства, включая ответственность перед третьим лицами в случаях, когда получение, распространение и/или иное использование такого Контента или его содержание нарушает применимое законодательство или права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/ или посягает на принадлежащие им нематериальные блага.</p>
        <p><strong>5.7.</strong> Правообладатель вправе, но не обязан осуществлять проверку содержания, подлинности и безопасности Контента Пользователей либо его компонентов, а равно его соответствия требованиям применимого права, и наличия у Пользователей необходимого объема прав на его распространение и/или использование.</p>
        <p><strong>5.8.</strong> В случае обнаружения нарушения прав и/или интересов в связи с использованием Платформы, в том числе размещением ненадлежащего Контента, следует сообщить об этом Правообладателю путем направления письменного уведомления с подробным изложением обстоятельств нарушения и ссылкой на Контент, содержащий материалы, которыми нарушаются соответствующие права и/или интересы.</p>
        <p><strong>5.9.</strong> При обнаружении ошибок в работе Платформы или Контенте сообщите об этом Правообладателю по адресу, указанному в реквизитах, или отдельно в Платформе для службы поддержки.</p>
        <p><strong>5.10.</strong> За любые нарушения Пользователем настоящего Соглашения и/или применимого права, а также за все последствия таких нарушений (включая любые убытки или ущерб, которые может понести Правообладатель и иные третьи лица) ответственность несет Пользователь.&nbsp;</p>
        <p><strong>5.11.</strong> В случае возникновения каких-либо претензий третьих лиц в отношении нарушения вами любых имущественных и/или личных неимущественных прав третьих лиц, а равно установленных законодательством запретов или ограничений вы обязаны по требованию Правообладателя пройти официальную идентификацию, предоставив Правообладателю нотариально заверенное обязательство урегулировать возникшие претензии собственными силами и за свой счет с указанием своих паспортных данных.</p>
        <p><strong>5.12.</strong> Правообладатель оставляет за собой право по своему усмотрению разрешать или запрещать загрузку, ограничивать доступ или удалять любой Контент, а также блокировать доступ к Платформе либо иным образом ограничивать (прекращать) использование Платформы Пользователем.</p>
        <p><strong>5.13.</strong> В случае привлечения Правообладателя к ответственности или наложения на него взыскания в связи с допущенными вами нарушениями прав и/или интересов третьих лиц, а равно установленных законодательством запретов или ограничений, вы обязаны в полном объеме возместить убытки Правообладателя.</p>
        <p><strong>5.14.</strong> Правообладатель не несет ответственность за любые убытки, причиненные Пользователю или любому третьему лицу в связи с использованием Платформы, включая, но не ограничиваясь, потерей прибыли, дохода, деловой репутации, данных или возможности использовать данные или устройства, даже в случае, если Правообладатель был предупрежден о возможности подобных убытков.</p>
        <p><strong>5.15.</strong> При любых обстоятельствах, если иное не предусмотрено Обязательными документами, ответственность Правообладателя ограничена возмещением документально подтвержденного реального ущерба в сумме, не превышающей 1 000 (одну тысячу) рублей, и возлагается на него исключительно при наличии в его действиях вины.&nbsp;</p>
    <p style="text-align: center"><strong>6. УВЕДОМЛЕНИЯ И РАЗРЕШЕНИЯ</strong></p>
        <p><strong>6.1.</strong> Вы соглашаетесь получать от Правообладателя на указанные при использовании Платформы адрес электронной почты, номер телефона или учетную запись в сервисах третьих лиц информационные электронные сообщения (далее &mdash; &laquo;нотификаторы&raquo;) о важных событиях, происходящих в Платформе.</p>
        <p><strong>6.2.</strong> Правообладатель также вправе использовать нотификаторы для информирования Пользователя об изменениях и новых возможностях Платформы и/или об изменении Соглашения или указанных в нем Обязательных документов, а также для доставки сообщений рекламно-информационного характера.</p>
        <p><strong>6.3.</strong> В случае, если Пользователь зарегистрирован и/или использует Платформу от лица и/или в интересах организации (включая индивидуальных предпринимателей), Правообладатель вправе указывать такую организацию, в том числе её логотип, в своих маркетинговых материалах в качестве лица, использующего Платформу.</p>
    <p style="text-align: center"><strong>7. СОГЛАШЕНИЕ ОБ ИСПОЛЬЗОВАНИИ ПРОСТОЙ ЭЛЕКТРОННОЙ ПОДПИСИ</strong></p>
        <p><strong>7.1.</strong> Во взаимоотношениях между Правообладателем и Пользователем могут использоваться электронные документы, удостоверенные простой электронной подписью.&nbsp;</p>
        <p><strong>7.2.</strong> Простой электронной подписью признается электронная подпись, которая посредством использования Аутентификационных данных Пользователя или указанного при использовании Платформы адреса электронной почты Пользователя (ключ электронной подписи) подтверждает факт формирования электронной подписи непосредственно Пользователем.</p>
        <p><strong>7.3.</strong> По соглашению Пользователя и Правообладателя электронные документы, подписанные простой электронной подписью, признаются равнозначными документам на бумажных носителях, подписанным собственноручной подписью.</p>
        <p><strong>7.4.</strong> Правообладатель определяет Пользователя, которому соответствует простая электронная подпись, по используемым Пользователем Аутентификационным данным, - в случае совершения любых действий по использованию Платформы, либо по используемому Пользователем адресу электронной почты &ndash; в случае поступления Правообладателю сообщений с такого адреса.</p>
        <p><strong>7.5.</strong> Любые действия, совершенные с использованием простой электронной подписи определенного Пользователя, считаются совершенными таким Пользователем.&nbsp;</p>
        <p><strong>7.6.</strong> Пользователь обязуется соблюдать конфиденциальность ключа электронной подписи. В частности, Пользователь не имеет права передавать Авторизационные данные или предоставлять доступ к своему Личному кабинету и электронной почте третьим лицам, и несет полную ответственность за их сохранность и индивидуальное использование, самостоятельно выбирая способ их хранения и ограничения к ним доступа.</p>
        <p><strong>7.7.</strong> В случае несанкционированного доступа к Личному кабинету, утраты или раскрытия третьим лицам Аутентификационных данных Пользователь обязан незамедлительно сообщить об этом Правообладателю путем направления электронного письма с указанного при использовании Платформы адреса электронной почты.</p>
        <p><strong>7.8.</strong> В случае несанкционированного доступа к электронной почте, адрес которой указан при использовании Платформы, утраты или раскрытия третьим лицам логина и пароля, используемым Пользователем для авторизации в службе такой электронной почты, Пользователь обязан незамедлительно заменить такой адрес в Платформе на новый и сообщить о данном факте Правообладателю путем направления электронного письма с нового адреса электронной почты.</p>
    <p style="text-align: center"><strong>8. ПРОЧИЕ УСЛОВИЯ</strong></p>
        <p><strong>8.1.</strong> Пользователь самостоятельно определяет цели и порядок использования функциональных возможностей Платформы, которые, однако, ни при каких условиях не могут противоречить настоящему Соглашению.</p>
        <p><strong>8.2 Применимое право</strong>. Настоящее Соглашение, порядок его заключения и исполнения, а также вопросы, не урегулированные настоящим Соглашением, регулируются действующим законодательством Российской Федерации.</p>
        <p><strong>8.3 Арбитраж</strong>. Все споры по Соглашению или в связи с ним подлежат рассмотрению в суде по месту нахождения Правообладателя в соответствии с действующим процессуальным правом Российской Федерации.</p>
        <p><strong>8.4 Изменения</strong>. Настоящее Соглашение может быть изменено или прекращено Правообладателем в одностороннем порядке без предварительного уведомления Пользователя и без выплаты какой-либо компенсации в связи с этим.</p>
        <p><strong>8.5 Редакция Соглашения</strong>. Действующая редакция настоящего Соглашения размещена на Сайте и доступна в сети Интернет по адресу <a href="https://www.testograf.ru/ru/docs-eula" target="_blank">https://www.testograf.ru/ru/docs-eula</a> .</p>
    <p style="text-align: center"><strong>9. РЕКВИЗИТЫ ПРАВООБЛАДАТЕЛЯ</strong></p>
<p>ООО &laquo;ТЕСТОГРАФ&raquo; ИНН/КПП 5003118105/500301001 142034, МО, пос.Мещерино, мкр.Южные горки, Квартал 6, д.45/2 E-mail: info@testograf.ru</p>
<p><br></p>
<p>Действующая редакция Пользовательского соглашения от 01.12.2023 г.</p>
</div>`;
