import { useMobile } from '@webapp/common/hooks/use-mobile';

import Bubble from 'assets/main-page/bubble1.png';
import Diagram from 'assets/main-page/diagram5.png';
import { Button } from 'components/ui/button';

import Circle from './img/circle.svg';

import css from './about.css';

export const About: FC = () => {
    const isMobile = useMobile();
    return (
        <div className={css.about}>
            <div className={css.container}>
                <div className={css.title}>Почему выбирают testograf</div>
                <div className={css.wrap}>
                    <div className={css.blocksWrap}>
                        <div className={css.blocks}>
                            <div className={css.blueBlock}>
                                <div className={css.blueBlockTitle}>Стабильность работы</div>
                                <div className={css.text}>
                                    Современный стек технологий, регулярная оптимизация кода и высокие требования к
                                    серверной инфраструктуре позволяют сервису быть доступным 99,99% времени в год. А
                                    если на вашу анкету одновременно зайдет 50.000 респондентов? Сервис опросов умеет
                                    держать и более высокие нагрузки.
                                </div>
                                <img alt='diagramImg' className={css.diagramImg} src={Diagram} />
                            </div>
                            <div className={css.blueBlock}>
                                <div className={css.blueBlockTitle}>Информационная безопасность</div>
                                <div className={css.text}>
                                    Внедряются и реализуются организационные и технические меры защиты информации:
                                    шифрование данных, защита от DDos и хакерских атак, ежедневное резервное копирование
                                    данных, двойная аутентификация пользователей, организация внутреннего контроля ИТ- и
                                    ИБ- процессов. Сервис входит в реестр отечественного ПО, а серверная инфраструктура
                                    расположена на территории России.
                                </div>
                                <Circle className={css.circleIcon} />
                            </div>
                        </div>
                        {!isMobile && (
                            <Button
                                className={css.btn}
                                href='https://zayavka-na-onlayn-prezentaciyu.testograf.ru'
                                target='_blank'
                            >
                                Оставить заявку на онлайн-презентацию
                            </Button>
                        )}
                    </div>
                    <div className={css.whiteBlock}>
                        <div className={css.whiteBlockTitle}>Огромные возможности конструктора</div>
                        <div className={css.text}>
                            Платформа для опросов testograf предлагает 30+ типов вопросов, простую и сложную логику
                            переходов и множество дополнительных настроек. Вы можете полностью кастомизировать дизайн
                            страницы опроса в соответствии с корпоративным стилем компании и распространить его через
                            множество доступных в конструкторе каналов, включая встроенные инструменты рассылки. При
                            поступлении первых ответов будет доступна продвинутая аналитика результатов и настройка
                            отчёта для выгрузки. Также доступно API для интеграции testograf с внутренними системами
                            вашей компании и автоматизации процессов.
                        </div>
                        <div className={css.video}>
                            <video
                                className={css.video}
                                style={{ borderRadius: isMobile ? '10px' : '30px', width: '100%' }}
                                autoPlay
                                muted
                                controls
                                loop
                                src='https://core.testograf.ru/files/%D0%92%D0%B8%D0%B4%D0%B5%D0%BE%20FAQ/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F/%D0%A1%D0%BE%D0%B7%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5-%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D0%B0.mp4'
                            />
                        </div>
                    </div>
                    {isMobile && (
                        <Button
                            className={css.btn}
                            href='https://zayavka-na-onlayn-prezentaciyu.testograf.ru'
                            target='_blank'
                        >
                            Оставить заявку на онлайн-презентацию
                        </Button>
                    )}
                </div>
            </div>
            <img alt='bubble' className={css.bubble} src={Bubble} />
        </div>
    );
};
