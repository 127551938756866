import cn from 'classnames';

import { useMobile } from '@webapp/common/hooks/use-mobile';

import Bubble from 'assets/main-page/bubble2.png';
import Diagram from 'assets/main-page/diagram.png';

import Gear from '../img/gear.svg';
import Mail from '../img/mail.svg';
import Text from '../img/text.svg';
import css from '../possibilities.css';

export const OrganizeTesting = () => {
    const isMobile = useMobile();
    return (
        <div className={css.section}>
            <div className={css.title}>Организуйте тестирования и опросы сотрудников</div>
            <div className={css.blocksWrap}>
                <div className={css.smallBlocks}>
                    <div className={cn(css.block, css.blue)}>
                        <img alt='cash' className={css.icon} src={Diagram} />
                        <div className={css.whiteText}>
                            Фильтрация результатов в разрезе
                            <div className={css.whiteTextSmall}>(команды, филиала, должности…)</div>
                        </div>
                    </div>
                    {!isMobile && (
                        <>
                            <div className={cn(css.block, css.white)}>
                                <Gear />
                                <div className={css.blackText}>Настраиваемая логика для разных уровней персонала</div>
                            </div>
                            <div className={cn(css.block, css.grey)}>
                                <Text />
                                <div className={css.blackText}>
                                    Запуск как полностью анонимных, так и не анонимных опросников
                                </div>
                            </div>
                        </>
                    )}
                    <div className={cn(css.block, css.grey)}>
                        <Mail />
                        <div className={css.blackText}>
                            Автоматический сбор и анализ ответов, отправка приглашений на email
                        </div>
                    </div>
                    {isMobile && (
                        <div className={css.mobileBlocks}>
                            <div className={cn(css.block, css.grey, css.width)}>
                                <Text />
                                <div className={css.blackText}>
                                    Запуск как полностью анонимных, так и не анонимных опросников
                                </div>
                            </div>
                            <div className={cn(css.block, css.white, css.width)}>
                                <Gear />
                                <div className={css.blackText}>Настраиваемая логика для разных уровней персонала</div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cn(css.bigBlock, css.background1)}>
                    <div className={css.bigBlockTitle}>
                        Оценка профессиональных навыков, уровня вовлечённости и удовлетворённости сотрудников — важный
                        элемент успешного управления командой.
                    </div>
                    <div className={css.bigBlockText}>
                        <div>
                            С помощью сервиса опросов Тестограф вы сможете легко создавать и настраивать тесты для
                            оценки знаний, анкетирования и формы для опросов внутри компании. Установите необходимые
                            ограничения для доступа к тесту, настройте логику переходов между вопросами и следите за
                            результатами в реальном времени.
                        </div>
                        <div>
                            Повысьте эффективность обучения, уровень вовлечённости и продуктивность вашей команды на
                            основании детализированных отчётов и автоматической аналитики.
                        </div>
                    </div>
                    <a
                        className={css.link}
                        href='https://www.testograf.ru/ru/capabilities-hr'
                        target='_blank'
                        rel='noreferrer'
                    >
                        Узнать больше
                    </a>
                </div>
            </div>
            <img alt='bubble' className={css.bubbleLeft} src={Bubble} />
        </div>
    );
};
