import cn from 'classnames';

import { useMobile } from '@webapp/common/hooks/use-mobile';

import BubbleRight from 'assets/main-page/bubble10.png';
import Bubble from 'assets/main-page/bubble7.png';
import Phone from 'assets/main-page/phone.png';
import { Button } from 'components/ui/button';

import ArrowUp from './img/arrow-up.svg';

import css from './editable-survey-templates.css';

export const EditableSurveyTemplates = () => {
    const isMobile = useMobile();
    return (
        <div className={css.editableSurveyTemplates}>
            <div className={css.title}>Редактируемые шаблоны опросов</div>
            <div className={css.description}>
                Выбирайте из 100+ профессиональных шаблонов опросов в сфере маркетинга и HR. Вы можете адаптировать
                шаблоны под себя, изменяя формулировки, а также добавляя или удаляя вопросы.
            </div>
            <div className={css.blocks}>
                <div className={cn(css.block, css.gradient1)} id='editableSurveyTemplatesBlock'>
                    <div className={css.blockTitle}>Опросы сотрудников</div>
                    <div className={css.points}>
                        <a
                            href='http://testograf.ru/ru/blog/employee-engagement-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} /> Оценка вовлеченности персонала
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/employee-satisfaction-survey-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} /> Опрос удовлетворенности сотрудников
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/enps-survey-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} /> Опрос eNPS - индекс лояльности сотрудников
                        </a>
                        <a href='' className={css.point} target='_blank' rel='noreferrer'>
                            <ArrowUp className={css.arrow} /> Анкета оценки 360 градусов
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/survey-360-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} /> Анкета при увольнении сотрудника
                        </a>
                    </div>
                </div>
                <div className={cn(css.block, css.gradient2)}>
                    <div className={css.blockTitle}>Опросы клиентов</div>
                    <div className={css.points}>
                        <a
                            href='https://www.testograf.ru/ru/blog/nps-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} /> Шаблон опроса NPS
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/csi-survey-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Опрос удовлетворенности CSI
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/csat-survey-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Оценка клиентского опыта (CSAT)
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/customer-service-quality-survey-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Анкета оценки качества обслуживания
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/design-choice-and-product-characteristics-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Опрос по выбору дизайна и характеристикам продукта
                        </a>
                    </div>
                </div>
                <div className={cn(css.block, css.gradient3)}>
                    <div className={css.blockTitle}>Голосования</div>
                    <div className={css.points}>
                        <a
                            href='https://www.testograf.ru/ru/blog/image-rating-vote-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Анкета для оценки изображений
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/best-choice-vote-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Выбор лучшего
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/best-image-vote-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Голосование за лучшее изображение
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/event-feedback-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Анкета обратной связи после мероприятия
                        </a>
                        <a
                            href='https://www.testograf.ru/ru/blog/speaker-evaluation-vote-template'
                            className={css.point}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <ArrowUp className={css.arrow} />
                            Оценка спикера на конференции
                        </a>
                    </div>
                </div>
            </div>
            <Button className={css.button} href='https://www.testograf.ru/ru/templates'>
                Посмотреть все шаблоны
            </Button>
            <div className={css.exampleBlock}>
                <div className={css.videoBlock}>
                    <div className={css.text}>
                        Testograf позволяет кастомизировать дизайн опроса до мелочей, в полном соответствии с вашим
                        корпоративным стилем:
                    </div>
                    <video
                        className={css.video}
                        style={{ borderRadius: isMobile ? '10px' : '30px', width: '100%' }}
                        autoPlay
                        muted
                        controls
                        loop
                        src='https://core.testograf.ru/files/%D0%92%D0%B8%D0%B4%D0%B5%D0%BE%20FAQ/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F/%D0%94%D0%B8%D0%B7%D0%B0%D0%B9%D0%BD-%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D0%B0.mp4'
                    />
                </div>
                <img alt='phone' className={css.icon} src={Phone} />
                <img alt='bubble' className={css.bubble} src={Bubble} />
            </div>
            <img alt='bubble' className={css.bubbleRight} src={BubbleRight} />
        </div>
    );
};
