import type { ActionCreator, AnyAction, AsyncThunk } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import type { AppDispatch, RootState } from 'resources/store';

interface ThunkAPIConfig {
    state: RootState;
    dispatch: AppDispatch;
}

// TODO return thunk type
// https://github.com/reduxjs/redux-toolkit/issues/486#issuecomment-799423405

// TODO purge, useAction only
export const useActions = <Arg, Returned>(
    action: ActionCreator<AnyAction> | AsyncThunk<Returned, Arg, ThunkAPIConfig>
): any => {
    const dispatch = useDispatch<AppDispatch>();

    return useMemo(() => bindActionCreators(action, dispatch), [action, dispatch]);
};

export const useAction = <Act = AnyAction>(action: ActionCreator<Act>): ActionCreator<Act> => {
    const dispatch = useDispatch<AppDispatch>();

    return useMemo(() => bindActionCreators(action, dispatch), [action, dispatch]);
};
export const useAsync = <Arg, Returned>(
    action: AsyncThunk<Returned, Arg, ThunkAPIConfig>
): AsyncThunk<Returned, Arg, ThunkAPIConfig> => {
    const dispatch = useDispatch<AppDispatch>();

    return useMemo(() => bindActionCreators(action, dispatch), [action, dispatch]);
};
