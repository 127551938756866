import { AccountRoutes } from 'resources/routes';

import { Button } from 'components/ui/button';

import Mountains from './img/mountains.svg';

import css from './demo-access.css';

export const DemoAccess: FC = () => {
    return (
        <div className={css.wrapper}>
            <div className={css.textWrap}>
                <div className={css.titleWrap}>
                    <div className={css.title}>Демо-доступ</div>
                    <div className={css.description}>Протестируйте конструктор опросов, создайте бесплатный опрос.</div>
                </div>
                <div className={css.text}>
                    После регистрации создайте тестовый опрос, соберите на него до 10 ответов, познакомьтесь с разделом
                    аналитики – здесь доступны фильтры по ответам, кастомизация отчётов и выгрузка результатов.
                </div>
            </div>
            <div className={css.btnWrap}>
                <Button href='/ru/pricing-plans' size='medium' className={css.whiteBtn}>
                    Тарифы
                </Button>
                <Button href={AccountRoutes.authRegister.link} color='blue' size='medium'>
                    Демо-регистрация
                </Button>
            </div>
            <Mountains className={css.mountains} />
            <div className={css.lines}>
                <div className={css.line} />
                <div className={css.line} />
                <div className={css.line} />
                <div className={css.line} />
            </div>
        </div>
    );
};
