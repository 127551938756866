import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';

import { roundFloat } from '@webapp/common/lib/ui';

export enum SurveyLimit {
    None,
    // eslint-disable-next-line no-bitwise
    Email = 1 << 0,
    // eslint-disable-next-line no-bitwise
    Volume = 1 << 1,
    // eslint-disable-next-line no-bitwise
    Response = 1 << 2,
    // eslint-disable-next-line no-bitwise
    All = Email | Volume | Response
}

export const hasLimit = (value: number, limit: SurveyLimit): boolean => {
    // eslint-disable-next-line no-bitwise
    return !Boolean(value & limit); // TODO core#224
};

export enum LicenseType {
    DEMO = 0,
    ONETIME = 1,
    FORYEAR = 2,
    FORUSER = 3,
    // ADMSPEC = 4,
    FORYEARPLUS = 5,
    RESPONDENTPANEL = 6
}

export const LicenseNames = {
    [LicenseType.DEMO]: 'Демо-лицензия',
    [LicenseType.ONETIME]: 'Разовая лицензия',
    [LicenseType.FORUSER]: 'Доп. пользователи',
    [LicenseType.FORYEAR]: 'Годовая лицензия',
    [LicenseType.FORYEARPLUS]: 'Годовая лицензия'
};

export interface OneTimeLicense {
    id: number;
    name: string;

    from: dayjs.Dayjs | null;
    to: dayjs.Dayjs | null;

    volume: number;
    volumeUsed: number;

    emailCount: number;
    emailCountUsed: number;
    emailLeft: number;

    responseCount: number;
    responseCountUsed: number;

    limits: SurveyLimit;
    lowLimit: SurveyLimit;
}

export interface License extends OneTimeLicense {
    licenseId: LicenseType;

    withActiveLicense: boolean;

    apiCount: number;
    apiCountUsed: number;

    // one-time license count
    oneCount: number;
    oneCountUsed: number;

    userCount: number;
    userCountUsed: number;

    surveys: Array<OneTimeLicense>;
}

export const initialLicense: Readonly<License> = {
    id: -1,
    name: '',

    withActiveLicense: false,

    licenseId: null,

    limits: SurveyLimit.All,
    lowLimit: SurveyLimit.All,

    from: null,
    to: null,

    apiCount: null,
    apiCountUsed: null,

    oneCount: 0,
    oneCountUsed: 0,

    userCount: 0,
    userCountUsed: 0,

    responseCount: 0,
    responseCountUsed: 0,

    volume: 0,
    volumeUsed: 0,

    emailCount: 0,
    emailCountUsed: 0,
    emailLeft: 0,

    surveys: []
};

const dateOrNull = (date: string | Date): dayjs.Dayjs | null => {
    const res = dayjs(date);
    return res.isValid() ? res : null;
};

export const mapLicense = (data: any): License => ({
    ...cloneDeep(initialLicense),

    name: LicenseNames[data.LICENSE_ID],

    licenseId: data.LICENSE_ID,

    withActiveLicense: data.LICENSE_STATUS,

    from: dateOrNull(data.VALID_FROM),
    to: dateOrNull(data.VALID_TO),

    apiCount: data.VALID_API,
    apiCountUsed: data.used.VALID_API,

    oneCount: data.VALID_SURVEY,
    oneCountUsed: data.used.VALID_SURVEY,

    userCount: data.VALID_USER,
    userCountUsed: data.used.VALID_USER,

    volume: roundFloat(data.VALID_VOLUME, 2),
    volumeUsed: roundFloat(data.used.VALID_VOLUME, 2),

    emailCount: data.VALID_EMAIL,
    emailCountUsed: data.used.VALID_EMAIL,
    emailLeft: data.VALID_EMAIL - data.used.VALID_EMAIL,

    responseCount: data.VALID_RESPONSE,
    responseCountUsed: data.used.VALID_RESPONSE
});

export const mapQuotas = (surveys): Array<OneTimeLicense> =>
    surveys.map(({ ACTIVATE_DATE, ID, LIMIT_EXCEEDED, LOW_LIMIT, NAME, quotas, START_DATE, STOP_DATE, used }) => ({
        id: ID,

        name: NAME,

        from: dateOrNull(ACTIVATE_DATE || START_DATE),
        to: dateOrNull(quotas.VALID_DATE || STOP_DATE),

        limits: LIMIT_EXCEEDED,
        lowLimit: LOW_LIMIT,

        volume: roundFloat(quotas.VALID_VOLUME, 2),
        volumeUsed: roundFloat(used.VALID_VOLUME, 2),

        emailCount: quotas.VALID_EMAIL,
        emailCountUsed: used.VALID_EMAIL,
        emailLeft: quotas.VALID_EMAIL - used.VALID_EMAIL,

        responseCount: quotas.VALID_RESPONSE,
        responseCountUsed: used.VALID_RESPONSE
    }));

export const LICENSE_AVAILABLE: Array<LicenseType> = [
    LicenseType.ONETIME,
    LicenseType.FORYEAR,
    LicenseType.FORUSER,
    LicenseType.FORYEARPLUS
];

export interface LicenceInfo {
    isDemo: boolean;
    isPro: boolean;
    isProPlus: boolean;
    isSizeExceeded: boolean;
    licenseId: LicenseType;
    availableLicenseNumber: number;
    hasAvailableUsers: boolean;
    isSingle: boolean;
    isNoLicense: boolean;
    withActiveLicense: boolean;
}
