import cn from 'classnames';
import { useEffect, useState } from 'react';

import { Layout } from '@webapp/landing/src/components/layout';

import { ApiIntegration } from 'components/mainpage/sections/api-integration';
import { DemoAccess } from 'components/mainpage/sections/demo-access';
import { DistributionSurvey } from 'components/mainpage/sections/distribution-survey';
import { EditableSurveyTemplates } from 'components/mainpage/sections/editable-survey-templates';
import { Possibilities } from 'components/mainpage/sections/possibilities';

import { About } from './sections/about';
import { Connect } from './sections/connect';
import { Feedback } from './sections/feedback';
import { Trust } from './sections/trust';

import css from './mainpage.css';

export const Mainpage: FC = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => setVisible(true), 100);
    }, []);

    return (
        <Layout className={cn(css.page, visible && css.visible)} headerClassName={css.header} page='main'>
            <Connect />
            <DemoAccess />
            <About />
            <Trust />
            <Possibilities />
            <ApiIntegration />
            <EditableSurveyTemplates />
            <DistributionSurvey />
            <Feedback />
        </Layout>
    );
};
