import cn from 'classnames';

import { useMobile } from '@webapp/common/hooks/use-mobile';

import Bubble from 'assets/main-page/bubble5.png';
import css from 'components/mainpage/sections/possibilities/possibilities.css';

export const AnalyzeData = () => {
    const isMobile = useMobile();
    return (
        <div className={css.section}>
            <div className={css.blocksWrap}>
                <div className={cn(css.bigBlock, css.background4)}>
                    <div className={css.bigBlockTitle}>
                        Анализируйте полученные данные <br className={css.br} /> и выгружайте их в удобном формате
                    </div>
                    <div className={css.bigBlockText}>
                        <div>
                            Уже при получении первых ответов на опрос вы получите автоматически сгенерированные таблицы
                            и диаграммы по каждому вопросу, а фильтры позволят просмотреть результаты в разрезе отдела,
                            должности, опыта или оценки NPS.
                        </div>
                        <div>
                            Стройте кросс-таблицы для наглядного представления сложных зависимостей, кастомизируйте
                            дашборды и выгружайте готовые отчёты в различных форматах. При необходимости поделиться
                            отчётом с коллегами просто отправьте им ссылку на результаты.
                        </div>
                    </div>
                </div>
                <video
                    className={css.video}
                    style={{ borderRadius: isMobile ? '10px' : '30px', width: '100%' }}
                    autoPlay
                    muted
                    controls
                    loop
                    src='https://core.testograf.ru/files/%D0%92%D0%B8%D0%B4%D0%B5%D0%BE%20FAQ/%D0%93%D0%BB%D0%B0%D0%B2%D0%BD%D0%B0%D1%8F/%D0%A0%D0%B5%D0%B7%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%82%D1%8B-%D0%BE%D0%BF%D1%80%D0%BE%D1%81%D0%B0.mp4'
                />
                <span className={css.bubbleRightAnalyzeData}>
                    <img alt='bubble' src={Bubble} />
                </span>
            </div>
        </div>
    );
};
