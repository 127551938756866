import cn from 'classnames';
import Link from 'next/dist/client/link';

import { Button as CommonButton } from '@webapp/ui/lib/button';

import css from './button.css';

export const Button: FC<{
    href?: string;
    target?: '_blank';
    onClick?: ReactMouseEventHandler;
    color?: 'blue' | 'white' | 'transparent';
    size?: 'small' | 'medium' | 'big';
    className?: string;
}> = ({ target, children, href, onClick, color = 'blue', size = 'medium', className }) => {
    const btn = (
        <CommonButton className={cn(css.button, className)} color={color} onClick={onClick} size={size}>
            {children}
        </CommonButton>
    );

    return href ? (
        target === '_blank' ? (
            <a href={href} target='_blank' rel='noreferrer'>
                {btn}
            </a>
        ) : (
            <Link href={href}>{btn}</Link>
        )
    ) : (
        btn
    );
};
