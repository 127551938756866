import cn from 'classnames';
import { AccountRoutes } from 'resources/routes';

import Diagram3 from 'assets/main-page/diagram3.png';
import Diagram4 from 'assets/main-page/diagram4.png';
import { Button } from 'components/ui/button';

import Folder from './img/folder.svg';
import MailingList from './img/mailing-list.svg';
import Qrcode from './img/qrcode.svg';
import Sms from './img/sms.svg';

import css from './distribution-survey.css';

export const DistributionSurvey = () => {
    return (
        <div className={css.distributionSurvey}>
            <div className={css.bigBlock}>
                <div className={css.bigBlockText}>
                    <div className={css.bigBlockTitle}>
                        Множество способов <br /> для распространения опроса
                    </div>
                    <div className={css.text}>
                        Используйте встроенные инструменты email-рассылки testograf для отправки приглашений к опросу и
                        напоминаний о необходимости заполнения. Укажите корпоративный ящик в качестве имени отправителя.
                        Рассылка входит во все тарифы, а лимиты обновляются ежесуточно.
                    </div>
                </div>
                <div className={css.buttons}>
                    <Button
                        className={css.distributionSurveyBtn}
                        href='https://zayavka-na-onlayn-prezentaciyu.testograf.ru'
                        target='_blank'
                    >
                        Оставить заявку на онлайн презентацию
                    </Button>
                    <Button
                        href={AccountRoutes.authRegister.link}
                        className={cn(css.distributionSurveyBtn, css.distributionSurveyWhiteBtn)}
                    >
                        Демо-регистрация
                    </Button>
                </div>
                <img alt='cash' className={cn(css.image, css.imageDiagram)} src={Diagram3} />
            </div>
            <div className={css.smallBlocks}>
                <div className={css.blocksRow}>
                    <div className={cn(css.block, css.grey)}>
                        <MailingList />
                        Рассылайте опрос по прямой ссылке через ваши средства коммуникации
                    </div>
                    <div className={cn(css.block, css.blue)}>
                        <img alt='cash' className={css.image} src={Diagram4} />
                        Встраивайте анкеты на сайт в виде виджетов, pop-up или iframe
                    </div>
                </div>
                <div className={cn(css.block, css.grey, css.blockHorizontal)}>
                    Воспользуйтесь возможностью отправки приглашений через sms-сообщения
                    <Sms className={css.icon} />
                </div>
                <div className={css.blocksRow}>
                    <div className={cn(css.block, css.white)}>
                        <Qrcode />
                        Разместите опросник в виде QR кода
                    </div>
                    <div className={cn(css.block, css.grey)}>
                        <Folder />
                        Собирайте данные оффлайн без подключения к интернету
                    </div>
                </div>
            </div>
        </div>
    );
};
