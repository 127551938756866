import { Advs } from 'components/ui/advs';

import css from './opportunities.css';

const templates = [
    {
        titleBig: 'Трудоустройство'
    },
    {
        title: 'Удовлетворенность процедурой приема на работу',
        href: 'https://www.testograf.ru/ru/blog/satisfaction-hiring-process-template'
    },
    {
        titleBig: 'Лояльность/Вовлеченность/Мотивация'
    },
    {
        title: 'Отношение к компании (опрос Липпонена)',
        href: 'http://testograf.ru/ru/blog/lipponen-survey-template'
    },
    {
        title: 'Уровень мотивации сотрудников',
        href: 'http://testograf.ru/ru/blog/employee-motivation-level-template'
    },
    {
        title: 'Тест на вовлеченность персонала (Gallup Q12)',
        href: 'http://testograf.ru/ru/blog/gallup-q12-survey-template'
    },
    {
        title: 'Вовлеченность сотрудников',
        href: 'http://testograf.ru/ru/blog/employee-engagement-template'
    },
    {
        title: 'Индекс лояльности сотрудников eNPS',
        href: 'https://www.testograf.ru/ru/blog/enps-survey-template'
    },
    {
        title: 'Тайм менеджмент',
        href: 'https://www.testograf.ru/ru/blog/time-management-survey-template'
    },
    {
        titleBig: 'Оценка / самооценка'
    },
    {
        title: 'Оценка Performance Review',
        href: 'https://www.testograf.ru/ru/blog/performance-review-survey-template'
    },
    {
        title: 'Оценка 90 градусов',
        href: 'https://www.testograf.ru/ru/blog/90-survey-template'
    },
    {
        title: 'Опрос 180 градусов для оценки руководителя',
        href: 'https://www.testograf.ru/ru/blog/180-survey-template'
    },
    {
        title: 'Круговая оценка работы персонала (Метод «360 градусов»)',
        href: 'https://www.testograf.ru/ru/blog/survey-360-template'
    },
    {
        title: 'Круговая оценка персонала "360 градусов" по компетенциям',
        href: 'https://www.testograf.ru/ru/blog/360-degree-survey-template'
    },
    {
        title: 'Самооценка сотрудника',
        href: 'https://www.testograf.ru/ru/blog/employee-self-assessment-template'
    },
    {
        title: 'Карьерные ожидания сотрудников',
        href: 'https://www.testograf.ru/ru/blog/career-expectations-of-employees-survey-template'
    },
    {
        title: 'Диагностики синдрома выгорания',
        href: 'https://www.testograf.ru/ru/blog/burnout-syndrome-test-template'
    },
    {
        title: 'Оценка руководителя (опрос Google)',
        href: 'https://www.testograf.ru/ru/blog/manager-assessment-survey-template'
    },
    {
        titleBig: 'Удовлетворенность'
    },
    {
        title: 'Анкета удовлетворенности сотрудников',
        href: 'https://www.testograf.ru/ru/blog/employee-satisfaction-survey-template'
    },
    {
        title: 'Оценка удовлетворенности сотрудников (опрос Спектора)',
        href: 'https://www.testograf.ru/ru/blog/spector-survey-template'
    },
    {
        title: 'Миннесотский опрос: Удовлетворенность сотрудников',
        href: 'https://www.testograf.ru/ru/blog/minnesota-survey-employee-satisfaction-template'
    },
    {
        title: 'Удовлетворенность работой и вознаграждениями',
        href: 'https://www.testograf.ru/ru/blog/job-satisfaction-and-rewards-survey-template'
    },
    {
        title: 'Удовлетворенность условиями труда с оценкой важности',
        href: 'https://www.testograf.ru/ru/blog/employee-satisfaction-pro-survey-template'
    },
    {
        titleBig: 'Уходящий сотрудник'
    },
    {
        title: 'Опрос уходящего сотрудника',
        href: 'https://www.testograf.ru/ru/blog/exit-interview-template'
    },
    {
        titleBig: 'Прочее'
    },
    {
        title: 'Корпоративная культура',
        href: 'https://www.testograf.ru/ru/blog/corporate-culture-survey-template'
    },
    {
        title: 'Диагностика аврала',
        href: 'https://www.testograf.ru/ru/blog/diagnosis-of-emergency-work-template'
    },
    {
        title: 'Диагностики сплочённости коллектива',
        href: 'https://www.testograf.ru/ru/blog/test-na-splocheniye-kollektiva'
    },
    {
        title: 'Опрос потребностей в обучении сотрудников',
        href: 'https://www.testograf.ru/ru/blog/employee-training-needs-survey-template'
    },
    {
        title: 'Опрос для оценки программы обучения',
        href: 'https://www.testograf.ru/ru/blog/training-program-survey-template'
    },
    {
        title: 'Оценка удовлетворенности обучением',
        href: 'https://www.testograf.ru/ru/blog/assessment-of-satisfaction-survey-template'
    },
    {
        title: 'Опрос по оценке результатов обучения',
        href: 'https://www.testograf.ru/ru/blog/assessment-of-learning-outcomes-survey-template'
    }
];

export const HR: FC<{ title?: string }> = ({ title }) => (
    <div className={css.hr}>
        <h1>{title}</h1>
        <p className='MsoNormal'>
            Платформа Тестограф предлагает широкие возможности для создания и проведения опросов сотрудников,
            предоставляя готовые шаблоны и гибкий конструктор для самостоятельного проектирования анкет.
        </p>
        <p className='MsoNormal'>
            На{' '}
            <a href='https://www.testograf.ru/' target='_blank' rel='noopener noreferrer nofollow'>
                Тестографе{' '}
            </a>
            компании могут легко организовать опросы для самых разных задач: от
            <span> онбординга новых сотрудников </span>и<span> оценки их вовлеченности </span>
            до проведения
            <span> опросов 360-градусов </span>
            или
            <span> Exit-интервью</span>. Каждый этап жизненного цикла сотрудника можно поддержать с помощью продуманных
            опросов, обеспечивая ценную обратную связь и анализ данных.
        </p>
        <p className='MsoNormal'>
            Сервис привлекает своей простотой и широкими функциональными возможностями, поэтому платформа подходит как
            для небольших команд, так и для крупных организаций.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Типы опросов для сотрудников на Тестографе</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Платформа Тестограф предоставляет
            <span> готовые шаблоны опросов </span>
            для различных этапов взаимодействия с сотрудниками. Эти шаблоны помогают быстро запустить нужные опросы,
            даже если у вас нет опыта в проектировании анкет. Вы можете использовать их как основу для дальнейшего
            редактирования и адаптации под нужды вашей компании.
        </p>
        <Advs items={templates} title='Шаблоны опросов для сотрудников' />
        <p className='MsoNormal'>
            Использование этих шаблонов позволяет не только экономить время, но и формировать опросы на основе
            проверенных методологий. Важно отметить, что каждый шаблон в Тестографе можно легко редактировать, добавляя
            или убирая вопросы, чтобы адаптировать анкету под уникальные потребности вашей компании.
        </p>
        <p className='alignCenter MsoNormal'>
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/auth/register'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Попробовать демо
            </a>{' '}
            &nbsp;
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/ru/templates'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Шаблоны опросов
            </a>
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Функциональные возможности конструктора</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Сервис предлагает профессиональный и интуитивно понятный конструктор, который позволяет создавать анкеты
            любой сложности. Независимо от того, создаёте ли вы опрос с нуля или редактируете готовый шаблон,
            возможности конструктора обеспечивают полную свободу в проектировании и адаптации опросов под ваши задачи.
            Основные функциональные возможности конструктора включают:
        </p>
        <ul>
            <li>
                <span>&nbsp; &nbsp;Разнообразие типов вопросов &nbsp;&nbsp;</span>
            </li>
        </ul>
        <p>
            В конструкторе Тестографа доступно более 30 различных типов вопросов, что позволяет гибко подойти к созданию
            опросов для сотрудников. Вы можете использовать стандартные варианты, такие как одиночный или множественный
            выбор, шкалы Лайкерта, а также более продвинутые инструменты, такие как матричные вопросы или вопросы с
            изображениями. Такой выбор дает возможность детализированного сбора информации.&nbsp;
        </p>
        <ul>
            <li>
                <span>&nbsp; &nbsp;Логические переходы и ветвления&nbsp; &nbsp;</span>
            </li>
        </ul>
        <p>
            Важным элементом конструктора является возможность настройки логики прохождения опроса. Логические переходы
            позволяют направлять респондентов по разным маршрутам опроса в зависимости от их ответов. Например, если
            сотрудник выбирает определенный вариант ответа, ему могут быть предложены последующие вопросы, релевантные
            его выбору. Это помогает сделать опрос более персонализированным и сокращает количество неактуальных
            вопросов.
        </p>
        <ul>
            <li>
                <span>&nbsp; &nbsp;Брендирование опросов&nbsp; &nbsp;</span>
            </li>
        </ul>
        <p>
            Если вы хотите, чтобы опросы выглядели более профессионально и отражали корпоративный стиль вашей компании,
            в Тестографе доступна функция брендирования опросов. Вы можете добавить логотип компании, выбрать цветовую
            схему, которая соответствует вашему бренду, и персонализировать внешний вид опроса. Это помогает сделать
            опросы более привлекательными и узнаваемыми для сотрудников.
        </p>
        <ul>
            <li>
                <span>&nbsp; &nbsp;Гибкость в настройке&nbsp; &nbsp;</span>
            </li>
        </ul>
        <p>
            Конструктор позволяет не только легко управлять вопросами, но и настроить весь процесс опроса — от
            оформления до выбора метода распространения. Все эти возможности делают Testograf&nbsp;удобным инструментом
            для разработки опросов разной сложности.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Аналитические возможности</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            <span lang='EN-US'>Testograf</span>
            <span lang='EN-US'>&nbsp;</span>не только помогает создавать опросы для персонала, но и предоставляет
            продвинутые инструменты для анализа полученных данных. С помощью расширенных аналитических функций вы
            сможете глубже понять, что стоит за ответами коллег, выявить ключевые тенденции и построить отчеты для
            дальнейшего принятия решений.
        </p>
        <ul>
            <li>
                <strong>Фильтрация данных</strong>
            </li>
        </ul>
        <p>
            После того как опрос завершен и собраны все ответы, вы можете воспользоваться функцией фильтрации данных.
            Это особенно полезно для того, чтобы
            <span> сегментировать респондентов по различным критериям</span>, например, по департаментам, должностям или
            стажу работы. Фильтры позволяют сосредоточиться на определенных группах сотрудников и анализировать их
            ответы отдельно от общего массива данных. Например, вы можете посмотреть, как оценка рабочего климата
            отличается между новыми сотрудниками и теми, кто работает в компании более года.
        </p>
        <ul>
            <li>
                <strong>Построение кросс-таблиц&nbsp;</strong>
            </li>
        </ul>
        <p>
            Еще одним важным аналитическим инструментом в Тестографе является возможность построения кросс-таблиц. Этот
            метод анализа позволяет сравнивать взаимосвязь между двумя или более переменными, что помогает глубже понять
            результаты. Например, можно{' '}
            <span>сопоставить уровень удовлетворенности сотрудников с их стажем работы</span>
            или департаментом, чтобы выявить скрытые закономерности и факторы, влияющие на мотивацию.
        </p>
        <ul>
            <li>
                <strong>Выгрузка данных в различных форматах&nbsp;</strong>
            </li>
        </ul>
        <p>
            Testograf&nbsp;предлагает удобные инструменты для экспорта данных в популярные форматы:
            <span> SPSS, PDF, XLSX и CSV.</span>
            Это дает возможность работать с результатами опросов в других системах и программных продуктах для
            проведения углубленного анализа. Формат SPSS особенно полезен для профессионалов в области статистики,
            которые хотят проводить расширенные статистические расчеты и модели на основе собранных данных. PDF и XLSX
            идеально подходят для создания отчетов, которые можно легко распространять среди коллег или руководства.
        </p>
        <ul>
            <li>
                <strong>Отчеты и визуализация данных&nbsp;</strong>
            </li>
        </ul>
        <p>
            Testograf&nbsp;позволяет не только выгружать сырые данные, но и автоматически
            <span> генерировать отчеты с графиками и диаграммами</span>. Эти визуальные отчеты значительно упрощают
            восприятие информации и позволяют быстро увидеть ключевые результаты. Например, вы можете получить график
            распределения ответов по степени удовлетворенности сотрудников или диаграмму, показывающую, как сотрудники
            оценивают различные аспекты своей работы.
        </p>
        <p className='MsoNormal'>
            Используя эти аналитические инструменты, вы сможете получить максимум пользы от данных, собранных в анкетах,
            и принять обоснованные решения для улучшения условий работы, мотивации и удержания персонала.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Возможности распространения опросов</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Конструктор предлагает несколько эффективных инструментов для распространения анкет среди коллег, что
            позволяет обеспечить высокий уровень участия и точность результатов. Эти функции помогают компаниям сделать
            процесс приглашения к опросу простым и удобным, как для организаторов, так и для респондентов.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Встроенный email-рассыльщик&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Один из ключевых инструментов распространения опросов в Тестографе — это встроенный email-рассыльщик. С его
            помощью можно <span>отправить опросы напрямую на электронные почты коллег</span>, не прибегая к сторонним
            сервисам. Это удобно, так как позволяет управлять всем процессом рассылки в рамках одной платформы. Более
            того, система поддерживает возможность массовых рассылок, что особенно важно для крупных компаний с большим
            количеством сотрудников.
        </p>
        <p className='MsoNormal'>
            Каждое письмо может содержать персонализированное приглашение к участию в опросе с уникальной ссылкой для
            каждого респондента. Это помогает отслеживать, кто уже прошел опрос, а кто еще не ответил. Также возможно
            настроить
            <span> автоматические напоминания </span>
            для тех сотрудников, которые не завершили опрос в указанный срок.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Отправка писем в рамках тарифов&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Возможность рассылки писем встроена в каждый тариф Тестографа, что делает этот функционал доступным для всех
            пользователей платформы. В зависимости от тарифного плана, вы можете отправлять письма разным объемам
            респондентов, что позволяет гибко управлять коммуникацией в зависимости от масштаба компании.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Демо-лицензия&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Для новых пользователей Тестограф предлагает
            <span> бесплатную демо лицензию</span>. После регистрации доступно
            <span> создание одного опроса и сбор до 10 ответов</span>. Это отличная возможность протестировать все
            ключевые функции платформы, включая конструктор опросов, инструменты распространения и базовые аналитические
            возможности.
        </p>
        <p className='alignCenter' style={{ marginTop: '0cm' }}>
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/auth/register'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Попробовать демо
            </a>
        </p>
        <p className='MsoNormal'>
            Демо-лицензия позволяет компаниям опробовать основные возможности Тестографа перед принятием решения о
            выборе тарифа. Вы можете создать пробный опрос, оценить удобство работы с платформой и качество полученных
            данных. Это особенно полезно для небольших компаний или команд, которые только начинают внедрять опросы
            сотрудников в свои бизнес-процессы.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Мобильная адаптация и доступность опросов&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Важно отметить, что все опросы, созданные на Тестографе,
            <span> автоматически адаптируются под мобильные устройства</span>. Это означает, что сотрудники смогут легко
            проходить опросы с любых устройств — смартфонов, планшетов или компьютеров. Такой подход повышает удобство
            для респондентов и способствует увеличению уровня участия в опросах.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Анонимные опросы&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            В Тестографе также предусмотрена возможность создания
            <span> полностью анонимных опросов</span>. Это важный инструмент для повышения честности и открытости
            ответов сотрудников, особенно при проведении опросов на чувствительные темы, такие как удовлетворенность
            работой или анализ корпоративной культуры. Анонимность гарантирует, что респонденты могут делиться своим
            мнением без страха негативных последствий, что значительно увеличивает доверие к процессу опроса и качество
            полученных данных.
        </p>
        <p className='MsoNormal'>
            Используя встроенные инструменты для распространения опросов, включая анонимные анкеты, вы сможете
            эффективно охватить всю вашу команду и собрать точные данные для анализа и принятия решений, которые
            повлияют на улучшение рабочих процессов и атмосферы в коллективе.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Интеграции и API</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Сервис не только предоставляет удобные инструменты для создания и распространения анкет, но и предлагает
            широкие возможности для интеграции с другими системами. Это позволяет легко встраивать опросы в существующие
            бизнес-процессы вашей компании и работать с данными в привычной для вас среде.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Открытое API&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Одним из ключевых преимуществ сервиса является наличие{' '}
            <a href='https://www.testograf.ru/ru/capabilities-api' target='_blank' rel='noopener noreferrer nofollow'>
                открытого API
            </a>
            , с помощью которого можно настроить интеграции с любыми сторонними сервисами и системами. Это дает
            возможность автоматизировать процесс сбора и анализа данных, передавая результаты опросов напрямую в
            корпоративные системы управления персоналом (HRIS), CRM или BI-платформы.
        </p>
        <p className='MsoNormal'>
            <strong>API позволяет:</strong>
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                Автоматически создавать опросы и управлять ими с помощью сторонних приложений.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Получать результаты опросов в режиме реального времени и передавать их в другие системы для анализа.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Интегрировать Тестограф с почтовыми сервисами или мессенджерами для автоматической рассылки приглашений
                на опросы.
            </li>
        </ul>
        <p className='MsoNormal'>
            Например, если ваша компания использует корпоративный портал для внутренних коммуникаций, вы можете встроить
            форму опроса прямо на его страницы или автоматизировать рассылку приглашений через корпоративную почту.
        </p>
        <p className='MsoNormal'>
            Благодаря этим возможностям,
            <span> Тестограф легко интегрируется в сложные бизнес-процессы </span>и может стать частью вашей общей
            ИТ-инфраструктуры.
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Преимущества Тестографа перед другими сервисами</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Когда речь идет о выборе платформы для проведения опросов сотрудников, важна не только функциональность, но
            и надежность, безопасность, а также оперативная поддержка. Платформа выделяется на фоне других сервисов
            рядом существенных преимуществ, которые делают его эффективным и надежным инструментом для решения задач
            компаний.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Стабильность работы&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Тестограф гарантирует высокий уровень доступности сервиса, который составляет 99.99% в год. Это означает,
            что ваши опросы будут доступны сотрудникам практически в любое время, без сбоев и технических проблем.
            Стабильная работа платформы особенно важна для крупных компаний, где задержки или неработающие опросы могут
            привести к потере данных или низкому уровню вовлеченности сотрудников. Надежная инфраструктура позволяет
            проводить опросы в запланированные сроки и без риска потери важных данных.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Функциональные возможности&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Одним из главных преимуществ Тестографа является его профессиональный конструктор опросов, который позволяет
            создавать анкеты практически любой сложности. В вашем распоряжении:
        </p>
        <p>
            &nbsp;1. Более 30 типов вопросов, начиная от стандартных вариантов (одиночный выбор, множественный выбор) до
            сложных логических блоков и матричных вопросов. &nbsp;2. Возможности настройки логических переходов между
            вопросами для создания персонализированных и релевантных анкет. &nbsp;3. Инструменты брендирования, которые
            позволяют оформить опросы в корпоративных цветах, добавлять логотипы и адаптировать внешний вид под бренд
            компании.
        </p>
        <p className='MsoNormal'>
            Эти возможности делают Тестограф гибким инструментом для решения любых задач, связанных с опросами
            персонала.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Безопасность данных&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Вопросы безопасности данных имеют первостепенное значение для любой компании, особенно когда речь идет о
            персональных данных сотрудников. В Тестографе процессы информационной безопасности выстроены в соответствии
            с международным циклом PDCA (Plan-Do-Check-Act). Платформа предлагает комплекс мер по защите данных:
        </p>
        <ol>
            <li style={{ marginTop: '0cm' }}>Шифрование данных для обеспечения конфиденциальности.</li>
            <li style={{ marginTop: '0cm' }}>Защита от DDoS-атак и хакерских угроз.</li>
            <li style={{ marginTop: '0cm' }}>
                Использование WAF (Web Application Firewall) для защиты веб-приложений.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Ежедневное резервное копирование данных, что обеспечивает сохранность информации даже в случае сбоя
                системы.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Двухфакторная аутентификация для повышения уровня защиты аккаунтов пользователей.
            </li>
            <li style={{ marginTop: '0cm' }}>
                Внутренний контроль ИТ- и ИБ-процессов, что гарантирует соответствие нормативным требованиям в сфере
                обработки персональных данных.
            </li>
        </ol>
        <p className='MsoNormal'>
            Эти меры делают Тестограф одной из самых безопасных платформ для проведения опросов сотрудников, что
            особенно важно для компаний, работающих с конфиденциальной информацией.
        </p>
        <ul>
            <li style={{ marginTop: '0cm' }}>
                <strong>Оперативная поддержка&nbsp;</strong>
            </li>
        </ul>
        <p style={{ marginTop: '0cm' }}>
            Команда поддержки Тестографа оперативно помогает пользователям решить любые вопросы, связанные с сервисом.
            Поддержка доступна по имейлу и телефону в рабочие часы с 10:00 до 18:00 по московскому времени. Вы можете
            рассчитывать на профессиональные консультации как по техническим вопросам, так и по методологии проведения
            опросов. В случае возникновения проблем с функционалом или сложностей в работе с конструктором, специалисты
            поддержки быстро помогут найти решение.
        </p>
        <p className='MsoNormal'>
            Это особенно важно для компаний, где опросы являются частью критически важных процессов, например, в рамках
            оценки удовлетворенности сотрудников или анализа их вовлеченности.
        </p>
        <ul>
            <li className='MsoNormal'>
                <strong>Современный стек технологий&nbsp;</strong>
            </li>
        </ul>
        <p className='MsoNormal'>
            Тестограф использует передовые технологии (React, PHP, Kubernetes), что позволяет сервису быстро
            адаптироваться под высокие нагрузки и масштабироваться в зависимости от роста пользователей и объема данных.
            Это позволяет регулярно выпускать обновления функционала и поддерживать стабильную работу даже при большом
            количестве одновременных респондентов. Использование современных технологий обеспечивает высокую
            производительность и стабильность платформы, что делает её идеальным выбором для компаний с большими
            требованиями к надежности.
        </p>
        <p className='alignCenter' style={{ marginTop: '0cm' }}>
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/auth/register'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Попробовать демо
            </a>{' '}
            &nbsp;
            <a
                className='uiButton blue'
                style={{ textDecoration: 'none' }}
                href='https://www.testograf.ru/ru/templates'
                target='_blank'
                rel='noopener noreferrer nofollow'
            >
                Шаблоны опросов
            </a>
        </p>
        <h2 className='MsoNormal'>
            <b>
                <strong>Заключение</strong>
            </b>
        </h2>
        <p className='MsoNormal'>
            Тестограф — это профессиональная платформа для создания опросов сотрудников, которая сочетает в себе
            функциональность, безопасность и надежность. Благодаря большому выбору готовых шаблонов и гибкому
            конструктору, компании могут быстро создавать опросы под любые задачи, адаптировать их под свои требования и
            анализировать результаты с помощью мощных инструментов аналитики. Высокий уровень безопасности данных,
            стабильность работы и оперативная поддержка делают Тестограф лидером среди сервисов для проведения опросов.
        </p>
        <p className='MsoNormal'>
            Если вы хотите повысить качество взаимодействия с вашими сотрудниками и получить ценную обратную связь, мы
            рекомендуем попробовать Тестограф, используя демо-лицензию, доступную после регистрации. Тестограф — это
            платформа, которая поможет вам эффективно управлять опросами и анализировать данные для улучшения
            бизнес-процессов.
        </p>
        <p className='MsoNormal'>&nbsp;</p>
        <h2>Видео-обучение по работе с Testograf:</h2>
        <div className='raw-html-embed'>
            <div style={{ position: 'relative', width: '100%', height: '0', paddingBottom: '56.25%' }}>
                <iframe
                    src='https://vk.com/video_ext.php?oid=-69308878&amp;id=456239081'
                    style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0' }}
                    allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
                    frameBorder='0'
                ></iframe>
            </div>
        </div>
    </div>
);
